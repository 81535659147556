function minTommss(minutes) {
    var sign = minutes < 0 ? "-" : "";
    var min = Math.floor(Math.abs(minutes));
    var sec = Math.floor((Math.abs(minutes) * 60) % 60);
    return sign + (min < 10 ? "0" : "") + min + ":" + (sec < 10 ? "0" : "") + sec;
}

function getDatasetAverage(dataset) {
    const setLength = dataset.length;
    let datasetTotal = 0;

    for (let i of dataset) {
        datasetTotal += i;
    }

    const average = parseFloat((datasetTotal / setLength).toFixed(2));

    return {
        dataset: new Array(setLength).fill(average),
        average: average
    };
}

fetch("assets/data/activities.json").then(
    (response) => response.json()
).then(data => {
    const activitiesTable = $("#activitiesTable").DataTable({
        bLengthChange: false,
        info: false,
        searching: true,
        ordering: true,
        order: [],
        pageLength: 10,
        scrollX: true,
        fixedColumns: {
            leftColumns: 2
        }
    });

    let totalRuns = 0;
    let totalKMs = 0.00;
    let totalHours = 0.00;
    let totalPaces = 0.00;

    let zeroToFivePaces = [];
    let zeroToFiveLabels = [];
    let fiveToEightPaces = [];
    let fiveToEightLabels = [];
    let eightToTenPaces = [];
    let eightToTenLabels = [];
    let tenAndUpPaces = [];
    let tenAndUpLabels = [];
    let distanceRanDates = [];
    let distanceRanKM = [];
    let allPaces = [];

    for (let i of data) {
        totalRuns += 1;

        var runDate = new Date(i["Date"]);
        var runDateString = runDate.toDateString().split(' ').slice(1).join(' ');
        var runDistance = parseFloat(i["Distance"].toFixed(2));
        var runDateDistance = runDateString + " (" + runDistance.toString() + "KM)";
        var runTime = minTommss(i["Time"] * 24 * 60);
        var runPace = minTommss(i["Avg Pace"] * 24);
        var runPaceFloat = parseFloat((i["Avg Pace"] * 24).toFixed(2));
        var runBestPace = minTommss(i["Best Pace"] * 24);

        totalHours += i["Time"] * 24;
        totalKMs += runDistance;
        totalPaces += runPaceFloat;

        activitiesTable.row.add([
            runDateString, runDistance, runTime + " Min",
            runPace + " Min/KM", runBestPace + " Min/KM",
            i["Avg HR"], i["Max HR"], i["Aerobic TE"], i["Avg Run Cadence"],
            i["Max Run Cadence"], i["Total Ascent"] + " M", i["Total Descent"] + " M",
            i["Avg Stride Length"] + " M"
        ]).draw();

        document.getElementById("total-km-hero").innerHTML = totalKMs.toFixed(2);
        document.getElementById("total-hours-hero").innerHTML = totalHours.toFixed(2);

        document.getElementById("lifetime-average-pace").innerHTML = minTommss(
            totalPaces / totalRuns
        ) + " Min/KM";

        document.getElementById("lifetime-average-distance").innerHTML = (
            totalKMs / totalRuns).toFixed(2
        ) + " KM/Run";

        document.getElementById("lifetime-total-distance").innerHTML = totalKMs.toFixed(2) + " KM";

        distanceRanDates.push(runDateString);
        distanceRanKM.push(runDistance);
        allPaces.push(runPaceFloat);

        if (runDistance < 5.0) {
            zeroToFivePaces.push(runPaceFloat);
            zeroToFiveLabels.push(runDateDistance);
        } else if (runDistance < 8.0) {
            fiveToEightPaces.push(runPaceFloat);
            fiveToEightLabels.push(runDateDistance);
        } else if (runDistance < 10.0) {
            eightToTenPaces.push(runPaceFloat);
            eightToTenLabels.push(runDateDistance);
        } else {
            tenAndUpPaces.push(runPaceFloat);
            tenAndUpLabels.push(runDateDistance);
        }
    }

    const zeroToFivePacesR = zeroToFivePaces.reverse();
    const zeroToFiveLabelsR = zeroToFiveLabels.reverse();

    var zeroToFiveChart = document.getElementById("zero-to-five-chart").getContext("2d");
    const zeroToFiveAverage = getDatasetAverage(zeroToFivePacesR);

    document.getElementById("zero-to-five-avg").innerHTML = zeroToFiveAverage["average"];

    new Chart(zeroToFiveChart, {
        type: "line",
        options: {
            plugins: {
                datalabels: {
                    display: false
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            }
        },
        data: {
            labels: zeroToFiveLabelsR,
            datasets: [
                {
                    label: "",
                    data: zeroToFivePacesR,
                },
                {
                    label: "",
                    data: zeroToFiveAverage["dataset"],
                }
            ]
        }
    }).canvas.parentNode.style.height = "360px";

    const fiveToEightPacesR = fiveToEightPaces.reverse();
    const fiveToEightLabelsR = fiveToEightLabels.reverse();

    var fiveToEightChart = document.getElementById("five-to-eight-chart").getContext("2d");
    const fiveToEightAverage = getDatasetAverage(fiveToEightPacesR);

    document.getElementById("five-to-eight-avg").innerHTML = fiveToEightAverage["average"];

    new Chart(fiveToEightChart, {
        type: "line",
        options: {
            plugins: {
                datalabels: {
                    display: false
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            }
        },
        data: {
            labels: fiveToEightLabelsR,
            datasets: [
                {
                    label: "",
                    data: fiveToEightPacesR,
                },
                {
                    label: "",
                    data: fiveToEightAverage["dataset"],
                }
            ]
        }
    }).canvas.parentNode.style.height = "360px";

    const eightToTenPacesR = eightToTenPaces.reverse();
    const eightToTenLabelsR = eightToTenLabels.reverse();

    var eightToTenChart = document.getElementById("eight-to-ten-chart").getContext("2d");
    const eightToTenAverage = getDatasetAverage(eightToTenPacesR);

    document.getElementById("eight-to-ten-avg").innerHTML = eightToTenAverage["average"];

    new Chart(eightToTenChart, {
        type: "line",
        options: {
            plugins: {
                datalabels: {
                    display: false
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            }
        },
        data: {
            labels: eightToTenLabelsR,
            datasets: [
                {
                    label: "",
                    data: eightToTenPacesR,
                },
                {
                    label: "",
                    data: eightToTenAverage["dataset"],
                }
            ]
        }
    }).canvas.parentNode.style.height = "360px";

    const tenAndUpPacesR = tenAndUpPaces.reverse();
    const tenAndUpLabelsR = tenAndUpLabels.reverse();

    var tenAndUpChart = document.getElementById("ten-and-up-chart").getContext("2d");
    const tenAndUpAverage = getDatasetAverage(tenAndUpPacesR);

    document.getElementById("ten-and-up-avg").innerHTML = tenAndUpAverage["average"];

    new Chart(tenAndUpChart, {
        type: "line",
        options: {
            plugins: {
                datalabels: {
                    display: false
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            }
        },
        data: {
            labels: tenAndUpLabelsR,
            datasets: [
                {
                    label: "",
                    data: tenAndUpPacesR,
                },
                {
                    label: "",
                    data: tenAndUpAverage["dataset"],
                }
            ]
        }
    }).canvas.parentNode.style.height = "360px";

    const distanceRanDatesR = distanceRanDates.reverse();
    const distanceRanKMR = distanceRanKM.reverse();
    const allPacesR = allPaces.reverse();

    var distanceRanChart = document.getElementById("distance-ran-chart").getContext("2d");

    new Chart(distanceRanChart, {
        type: "line",
        options: {
            plugins: {
                datalabels: {
                    display: false
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            }
        },
        data: {
            labels: distanceRanDatesR,
            datasets: [
                {
                    label: "",
                    data: distanceRanKMR,
                },
                {
                    label: "",
                    data: allPacesR,
                }
            ]
        }
    }).canvas.parentNode.style.height = "360px";
});
